import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS, USER_INTERACTIONS } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function accountPrivate__server__setConversationUpdatedAtMSForAccounts(p: {
  accountId: AccountId;
  convoId: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      settings: {
        participatingConversationUpdatedAtMS: Date.now(),
        mostRecentParticipatingConversationUpdatedId: p.convoId
      }
    }
  });
}

accountPrivate__server__setConversationUpdatedAtMSForAccounts.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
