import { Account } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function account__server__getAccountNoAuth(p: { accountId: string }): Promise<Account | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let res = await h.Account.getDoc(p.accountId);
  return res;
  // SERVER_ONLY_TOGGLE
}

account__server__getAccountNoAuth.auth = async () => {};

// i18n certified - complete
