import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS, AccountCollectionName } from "@ollie-sports/models";

export async function account__server__addAchievements(p: { accountId: AccountId; achievements: ACHIEVEMENTS[] }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const achievementsObj: { [a in ACHIEVEMENTS]?: true } = {};
  p.achievements.forEach(a => {
    achievementsObj[a] = true;
  });

  await h.Account.update({
    id: p.accountId,
    doc: { achievements: achievementsObj }
  });
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
