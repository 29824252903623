import { getServerHelpers } from "../../helpers";
import { AccountId, CONVERSATION_TYPES } from "@ollie-sports/models";
import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { playerBundle__server__removeManagedAccount } from "../playerBundle";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import Queue from "promise-queue";

export async function account__server__delete(p: { selfAccountId: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const q1 = new Queue(30);

  const { appFirebaseAdminApp, appOllieFirestoreV2: h } = getServerHelpers();

  // Change names of account to deleted
  q1.add(async () => {
    await h.Account.update({
      id: p.selfAccountId,
      doc: {
        firstName: "Deleted",
        lastName: "Account",
        phoneNumber: "",
        email: `DELETED_EMAIL-${Math.random()}@olliesports.com`,
        deletedAtMS: Date.now()
      }
    });
  });

  // Unlink from any bundles
  q1.add(async () => {
    const playerBundlesApartOf = await h.PlayerBundle.query({
      where: [{ managingAccounts: { [p.selfAccountId]: { exists: ["==", true] } } }]
    });
    for (let i = 0; i < playerBundlesApartOf.docs.length; i++) {
      const playerBundle = playerBundlesApartOf.docs[i];
      await playerBundle__server__removeManagedAccount({ managedAccountId: p.selfAccountId, playerBundleId: playerBundle.id });
    }
  });

  // Manually remove any teams, which will kill their staff permissions
  const teamsApartOf = await h.Team.query({ where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }] });
  for (let i = 0; i < teamsApartOf.docs.length; i++) {
    q1.add(async () => {
      const team = teamsApartOf.docs[i];
      await h.Team.update({ id: team.id, doc: { accounts: { [p.selfAccountId]: h._MagicDeleteValue } } });
      // For good measure update any derived from associated teams
      await updateDerivedForTeam({ teamId: team.id, executeImmediate: true });
    });
  }

  // Remove from an orgs admins
  const orgAdminsOf = await h.Org.query({ where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }] });
  for (let i = 0; i < orgAdminsOf.docs.length; i++) {
    const org = orgAdminsOf.docs[i];
    q1.add(async () => {
      await h.Org.update({ id: org.id, doc: { accounts: { [p.selfAccountId]: h._MagicDeleteValue } } });
    });
  }

  // Delete messages - set deleted at field and remove remove content
  const deleteMessages = async () => {
    const messages = await h.Message.query({ where: [{ accountId: ["==", p.selfAccountId] }] });
    for (let i = 0; i < messages.docs.length; i++) {
      let message = messages.docs[i];
      q1.add(async () => {
        await h.Message.update({
          id: message.id,
          doc: {
            deletedAtMS: Date.now(),
            text: h._MagicDeleteValue,
            videoThumbnailUri: h._MagicDeleteValue,
            videoUri: h._MagicDeleteValue
          }
        });
      });
      if (messages.nextQuery) {
        deleteMessages();
      }
    }
  };
  deleteMessages();

  const conversationsPartOf = await h.Conversation.query({
    where: [{ accounts: { [p.selfAccountId]: ["==", true] } }]
  });
  for (let i = 0; i < conversationsPartOf.docs.length; i++) {
    q1.add(async () => {
      const conv = conversationsPartOf.docs[i];
      if (conv.conversationType === CONVERSATION_TYPES.accounts && Object.keys(conv.accounts).length === 2) {
        // The in the case of a two person conversation we actually delete the entire conversation and any messages
        const messages = await h.Message.query({ where: [{ conversationId: ["==", conv.id] }] });
        for (let k = 0; k < messages.docs.length; k++) {
          q1.add(async () => {
            await h.Message.delete({ id: messages.docs[i].id });
          });
        }
        await h.Conversation.delete({ id: conv.id });
      } else {
        // Every other group conversation we just remove them from the conversation
        await h.Conversation.update({ id: conv.id, doc: { accounts: { [p.selfAccountId]: h._MagicDeleteValue } } });
      }
    });
  }

  // Remove any LGM custom events
  const customEvents = await h.LiveGameCustomEvent.query({ where: [{ creatingAccountId: ["==", p.selfAccountId] }] });
  for (let i = 0; i < customEvents.docs.length; i++) {
    const event = customEvents.docs[i];
    q1.add(async () => {
      const reactions = await h.LiveGameReaction.query({ where: [{ customSoccerGameEventId: ["==", event.id] }] });
      for (let k = 0; k < reactions.docs.length; k++) {
        const reaction = reactions.docs[k];
        await h.LiveGameReaction.delete({ id: reaction.id });
      }
      await h.LiveGameCustomEvent.delete({ id: event.id });
    });
  }

  // Remove any LGM reactions
  const lgmReactions = await h.LiveGameReaction.query({ where: [{ accountId: ["==", p.selfAccountId] }] });
  for (let i = 0; i < lgmReactions.docs.length; i++) {
    const reaction = lgmReactions.docs[i];
    q1.add(async () => {
      await h.LiveGameReaction.delete({ id: reaction.id });
    });
  }

  const waitForQueueToClear = async () => {
    await new Promise<void>(r => setTimeout(() => r(), 50));
    if (q1.getQueueLength() === 0 && q1.getPendingLength() === 0) {
      return;
    } else {
      await waitForQueueToClear();
    }
  };
  waitForQueueToClear();

  //Remove from firestore auth
  q1.add(async () => await appFirebaseAdminApp.auth().deleteUser(p.selfAccountId));

  // SERVER_ONLY_TOGGLE
}

account__server__delete.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
