import {
  TeamColorSettings,
  TeamId,
  CALENDAR_ENTRY_TYPES,
  EventColorSetting,
  TeamStatKeys,
  AccountId,
  PlayerStatKeys
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updatePlayerBundleTrendsStats(p: {
  accountId: AccountId;
  playerBundleId: string;
  trendsSettings: { [key in PlayerStatKeys]?: true };
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { playerBundleTrendsStats: { [p.playerBundleId]: true } } },
    value: { settings: { playerBundleTrendsStats: { [p.playerBundleId]: p.trendsSettings } } }
  });
}
// i18n certified - complete
