import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS } from "@ollie-sports/models";

export async function account__client__removeAchievement(p: { accountId: AccountId; achievement: ACHIEVEMENTS }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Account.update({
    id: p.accountId,
    doc: { achievements: { [p.achievement]: h._MagicDeleteValue } }
  });
}

// i18n certified - complete
