import _ from "lodash";
import LoginView from "../auth/LoginView";
import { useGlobalState } from "../../services/global-state.service";
import { translate } from "@ollie-sports/i18n";
import Logo from "../../components/Logo";
import { useState } from "react";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { StyledButton } from "../../components/StyledButton";
import { getBifrost } from "../../services/bifrost.service";
import { getCurrentUserAccountIdNoThrow } from "../../hooks/commonDataUtils";

export default function AccountDelete() {
  const isLoggedIn = useGlobalState().auth.isLoggedIn;
  const [input, setInput] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const selfAccountId = getCurrentUserAccountIdNoThrow();

  return (
    <div className={"flex flex-1 flex-col items-center py-16"}>
      <Logo variant="dark" />
      <div className="max-w-lg items-center">
        {hasDeleted ? (
          <div className="flex flex-1 flex-col items-center">
            <div className="mt-2 text-center">
              {translate({
                defaultMessage: `Your account has successfully been deleted`
              })}
            </div>
          </div>
        ) : isLoggedIn ? (
          <div className="flex flex-1 flex-col items-center">
            <div className="font-bold mt-4 text-2xl">{translate({ defaultMessage: "Delete your account?" })}</div>
            <div className="mt-2 text-center">
              {translate({
                defaultMessage: `This action cannot be undone. To delete your account, please type "CONFIRM".`
              })}
            </div>
            <CoolTextInput
              onChange={newVal => {
                setInput(_.toUpper(newVal));
              }}
              value={input}
              placeholder={translate({ defaultMessage: "CONFIRM" })}
              style={{ marginTop: 16 }}
            />
            <StyledButton
              style={{ marginTop: 16 }}
              onClick={async () => {
                setErrorMsg("");
                setIsLoading(true);
                try {
                  if (!selfAccountId) {
                    throw new Error("No account id");
                  }
                  await getBifrost().account__server__delete.fetchServer({ selfAccountId });
                  setHasDeleted(true);
                } catch (e) {
                  setErrorMsg(translate.common.SomethingWentWrong);
                }
                setIsLoading(false);
              }}
              className="bg-red-600 hover:bg-red-400 active:bg-red-700 focus:bg-red-600"
              text={translate.common.Delete}
              disabled={input !== "CONFIRM"}
              isLoading={isLoading}
            />
            {errorMsg ? <div className="mt-2 text-red-600">{errorMsg}</div> : null}
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center">
            <div className="mt-4">{translate({ defaultMessage: "Please log in to delete your account" })}</div>
            <LoginView className="scale-90" />
          </div>
        )}
      </div>
    </div>
  );
}
