import {
  TeamColorSettings,
  TeamId,
  CALENDAR_ENTRY_TYPES,
  EventColorSetting,
  TeamStatKeys,
  AccountId,
  PlayerStatKeys,
  TeamIdWithSquadProfileFilterSettings
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateTeamIdWithSquadProfileFilterSettings(p: {
  accountId: AccountId;
  teamIdWithSquad: string;
  teamIdWithSquadProfileFilterSettings?: TeamIdWithSquadProfileFilterSettings;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { teamIdWithSquadProfileFilterSettings: { [p.teamIdWithSquad]: true } } },
    value: {
      settings: {
        teamIdWithSquadProfileFilterSettings: {
          [p.teamIdWithSquad]: p.teamIdWithSquadProfileFilterSettings ?? h._MagicDeleteValue
        }
      }
    }
  });
}

// i18n certified - complete
