import { TeamColorSettings, TeamId, CALENDAR_ENTRY_TYPES } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateEventColor(p: {
  accountId: string;
  color: string;
  eventType: CALENDAR_ENTRY_TYPES;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      settings: {
        eventColors: {
          [p.eventType]: p.color
        }
      }
    }
  });
}

// i18n certified - complete
