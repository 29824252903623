import {
  TeamColorSettings,
  TeamId,
  CALENDAR_ENTRY_TYPES,
  EventColorSetting,
  TeamStatKeys,
  AccountId,
  PlayerStatKeys,
  PlayerBundleProfileFilterSettings
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updatePlayerBundleProfileFilterSettings(p: {
  accountId: AccountId;
  playerBundleId: string;
  playerBundleProfileFilterSettings?: PlayerBundleProfileFilterSettings;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { playerBundleProfileFilterSettings: { [p.playerBundleId]: true } } },
    value: {
      settings: {
        playerBundleProfileFilterSettings: { [p.playerBundleId]: p.playerBundleProfileFilterSettings ?? h._MagicDeleteValue }
      }
    }
  });
}

// i18n certified - complete
