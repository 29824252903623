import { AccountId, ConversationId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__setAccountMuting(p: {
  selfAccountId: AccountId;
  accountIdToMuteOrUnmute: AccountId;
  mute: boolean;
  conversationId: ConversationId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.AccountPrivate.update({
    id: p.selfAccountId,
    doc: {
      settings: {
        conversations: {
          [p.conversationId]: {
            mutedAccountIds: {
              [p.accountIdToMuteOrUnmute]: p.mute ? true : h._MagicDeleteValue
            }
          }
        }
      }
    }
  });
}

// i18n certified - complete
