import { TeamColorSettings, TeamId, CALENDAR_ENTRY_TYPES, EventColorSetting, EventReminderSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateEventReminderSettings(p: {
  accountId: string;
  eventReminderSettings: EventReminderSettings;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      settings: {
        eventReminderSettings: p.eventReminderSettings
      }
    }
  });
}

// i18n certified - complete
