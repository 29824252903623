import { AccountId } from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { getBatchTasksToUpdateDerivedForAccount } from "../../compute/account.compute";

export async function account__server__updateProfileImageUris(p: {
  accountId: AccountId;
  profileImageUri: string;
  profileImageUriSmall: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.Account.update({
    id: p.accountId,
    doc: { profileImageUri: p.profileImageUri, profileImageUriSmall: p.profileImageUriSmall }
  });

  await h._BatchRunner.executeBatch(await getBatchTasksToUpdateDerivedForAccount({ accountId: p.accountId }));

  // SERVER_ONLY_TOGGLE
}

account__server__updateProfileImageUris.auth = () => {};

// i18n certified - complete
