import {
  TeamColorSettings,
  TeamId,
  CALENDAR_ENTRY_TYPES,
  EventColorSetting,
  TeamStatKeys,
  AccountId
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateTeamTrendsStats(p: {
  accountId: AccountId;
  teamId: string;
  trendsSettings: { [key in TeamStatKeys]?: true };
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { teamTrendsStats: { [p.teamId]: true } } },
    value: { settings: { teamTrendsStats: { [p.teamId]: p.trendsSettings } } }
  });
}
// i18n certified - complete
