import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { PaymentMethod } from "@ollie-sports/models";
import _ from "lodash";

export async function accountSecret__server__getPaymentMethods(p: { selfAccountId: string }) {
  // TODO this should not be selfAccountId as club admins can manage account payment methods
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.selfAccountId);

  return _(accountSecret?.paymentMethodsById || {})
    .values()
    .orderBy(a => {
      if (a.isDefault) {
        return 1;
      } else {
        return 0;
      }
    }, "desc")
    .value();

  // SERVER_ONLY_TOGGLE
}

accountSecret__server__getPaymentMethods.auth = async (req: any) => {
  await validateToken(req);
};
