import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function account__server__validateEmail(p: { email: string }): Promise<boolean> {
  // SERVER_ONLY_TOGGLE
  const { serverConfig } = getServerHelpers();

  var postData = {
    email: p.email,
    source: "signup"
  };

  let axiosConfig = {
    headers: {
      authorization: `Bearer ${serverConfig.sendGridValidationApiKey}`,
      "content-type": "application/json"
    }
  };

  const valid = await axios
    .post("https://api.sendgrid.com/v3/validations/email", postData, axiosConfig)
    .then(res => {
      if (res.data.result.verdict === "Invalid") {
        return false;
      }
      return true;
    })
    .catch(err => {
      return true;
    });

  return valid;
  // SERVER_ONLY_TOGGLE
}

account__server__validateEmail.auth = () => {};

// i18n certified - complete
