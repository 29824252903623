import { TeamColorSettings, TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function account__client__updateTeamColor(p: { accountId: string; color: string; teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { teamColors: { [p.teamId]: true } } },
    value: {
      settings: { teamColors: { [p.teamId]: p.color } }
    }
  });
}

// i18n certified - complete
