import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { verifyIdToken } from "../../internal-utils/server-auth";

export async function account__server__exchangeClientTokenForJWTToken(p: { selfAccountId: string; token: string }) {
  // SERVER_ONLY_TOGGLE

  const { appFirebaseAdminApp } = getServerHelpers();

  const { uid } = await verifyIdToken(p.token);

  if (uid !== p.selfAccountId) {
    throw new Error("selfAccountId does not match!");
  }

  return await appFirebaseAdminApp.auth().createCustomToken(uid);

  // SERVER_ONLY_TOGGLE
}

account__server__exchangeClientTokenForJWTToken.auth = async (req: any) => {
  //Nothing... Validated in function body
};
