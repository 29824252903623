import { getUniversalHelpers } from "../../helpers";
import { AccountId } from "@ollie-sports/models";
import { account } from "../../compute";
import shortid from "shortid";

const IMAGE_DIRECTORY = "account_uploads/";

export async function account__client__uploadProfileImage(p: { blob: any; blobSmall: any; accountId: AccountId }) {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const acc = await h.Account.getDoc(p.accountId);
  if (!acc) {
    return;
  }

  // Try to delete the old profile images to save storage. If something goes wrong, just continue.
  // We don't want to stop uploading the profile image because the old one can't be deleted.
  try {
    if (acc.profileImageUri) {
      const deleteTask = app.storage().refFromURL(acc.profileImageUri).delete();
      await deleteTask;
    }

    if (acc.profileImageUriSmall) {
      const deleteTaskSmall = app.storage().refFromURL(acc.profileImageUriSmall).delete();
      await deleteTaskSmall;
    }
  } catch (e) {
    console.warn("Error deleting profile images");
  }

  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.accountId + "/" + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const uploadSmallTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.accountId + "/" + shortid() + ".jpg")
    .put(p.blobSmall);

  // tslint:disable-next-line: await-promise
  await uploadSmallTask;

  const storageUri = await uploadTask.snapshot.ref.getDownloadURL();
  const storageUriSmall = await uploadSmallTask.snapshot.ref.getDownloadURL();

  return { uri: storageUri, uriSmall: storageUriSmall };
}

// i18n certified - complete
