import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS, USER_INTERACTIONS } from "@ollie-sports/models";

export async function accountPrivate__client__setUserInteraction(p: {
  accountId: AccountId;
  userInteraction: USER_INTERACTIONS;
  value: any;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { userInteractions: { [p.userInteraction]: true } },
    value: { userInteractions: { [p.userInteraction]: p.value } }
  });
}

// i18n certified - complete
