import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS } from "@ollie-sports/models";

export async function account__client__addAchievement(p: { accountId: AccountId; achievement: ACHIEVEMENTS }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Account.update({
    id: p.accountId,
    doc: { achievements: { [p.achievement]: true } }
  });
}
// i18n certified - complete
