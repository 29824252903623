import { AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__incrementSessions(p: { accountId: AccountId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountPrivate = await h.AccountPrivate.getDoc(p.accountId);

  if (!accountPrivate) {
    return;
  }

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      userInteractions: {
        numberOfSessions: (accountPrivate.userInteractions?.numberOfSessions ?? 0) + 1
      }
    }
  });
}
