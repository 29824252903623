import { AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateTimezone(p: { accountId: AccountId; timezone: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      timezone: p.timezone
    }
  });
}
// i18n certified - complete
