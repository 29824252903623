import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { PaymentMethod } from "@ollie-sports/models";
import _ from "lodash";

export async function accountSecret__server__getAccountDefaultPaymentMethod(p: { accountId: string }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.accountId);

  const defaultMethods = Object.values(accountSecret?.paymentMethodsById || {}).filter(a => a.isDefault);

  if (defaultMethods.length) {
    return defaultMethods[0];
  }
  return null;

  // SERVER_ONLY_TOGGLE
}

accountSecret__server__getAccountDefaultPaymentMethod.auth = async (req: any) => {
  await validateToken(req);
};
