import { getUniversalHelpers } from "../../helpers";
import { AccountId, ACHIEVEMENTS, USER_INTERACTIONS } from "@ollie-sports/models";
import express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

export async function accountPrivate__server__getLicenseFamilyShare(this: ServerThisContext, p: { accountId: AccountId }) {
  // SERVER_ONLY_TOGGLE

  // return {
  //   message: this.translate({ defaultMessage: "hello" })
  // };

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountPrivate = await h.AccountPrivate.getDoc(p.accountId);

  return accountPrivate?.licenseFamilyShare;
  // SERVER_ONLY_TOGGLE
}

accountPrivate__server__getLicenseFamilyShare.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
