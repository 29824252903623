import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import {
  PaymentMethod,
  PaymentMethodOnCreation,
  PaymentMethodType,
  PaymentMethod__BankAccount,
  PaymentMethod__Card
} from "@ollie-sports/models";
import { DistributiveOmit } from "../../utils";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { isDeployedCode } from "../../utils/server-helpers";
import { encryptPIFI } from "../../utils/pifi-crypto-helpers";
import axios, { AxiosResponse } from "axios";
import { translate } from "@ollie-sports/i18n";
import { nmiSDK } from "../../utils/nmiSDK";
import { PRETTY_PAYMENT_ERROR_CODES } from "../../constants";
import { translateServer } from "../../utils/serverTranslate";

export async function accountSecret__server__addPaymentMethod(
  this: ServerThisContext,
  p: {
    selfAccountId: string;
    paymentMethod: PaymentMethodOnCreation;
    locale: string;
  }
): Promise<
  | {
      status: "success";
      paymentMethodId: string;
    }
  | { status: "error"; prettyErrorMsg: string }
> {
  // SERVER_ONLY_TOGGLE
  const proto = this.request.headers["x-forwarded-proto"] || this.request.protocol;

  if (isDeployedCode() && proto !== "https") {
    throw new Error("May only add payment methods over a secure connection!");
  }

  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.selfAccountId);

  if (!accountSecret) {
    throw new Error("Can't find account");
  }

  let oldDefaultPaymentMethod: PaymentMethod | undefined;
  if (p.paymentMethod.isDefault) {
    oldDefaultPaymentMethod = Object.values(accountSecret.paymentMethodsById ?? {}).find(
      paymentMethod => paymentMethod.isDefault
    );
  }

  //Verify the card is good with NMI

  const verifyResp =
    p.paymentMethod.type === "card"
      ? await nmiSDK.verifyCC({
          fullName: p.paymentMethod.ownerName,
          expMM: p.paymentMethod.expMM,
          expYYYY: p.paymentMethod.expYYYY,
          number: p.paymentMethod.cardNumber,
          cvv: p.paymentMethod.cvv,
          postalCode: p.paymentMethod.postalCode,
          accountId: p.selfAccountId
        })
      : await nmiSDK.verifyBankAccount({
          accountNumber: p.paymentMethod.accountNumber,
          accountType: p.paymentMethod.bankAccountType,
          fullName: p.paymentMethod.ownerName,
          routingNumber: p.paymentMethod.routingNumber,
          accountId: p.selfAccountId
        });

  if (verifyResp.type !== "success") {
    await olliePipe.emitEvent({
      type: "error-adding-payment-method",
      payload: {
        accountId: p.selfAccountId,
        errorCode: verifyResp.errorCode,
        errorFallbackText: verifyResp.fallbackErrorText
      }
    });

    const failureReason =
      PRETTY_PAYMENT_ERROR_CODES[verifyResp.errorCode || ""]?.(p.locale) ||
      verifyResp.fallbackErrorText ||
      translateServer({ defaultMessage: "Unknown Error", serverLocale: p.locale });

    return {
      status: "error",
      prettyErrorMsg: translate(
        {
          defaultMessage: "There was a problem authorizing your {entity} ({reason}). Please update your info and try again.",
          serverLocale: p.locale
        },
        {
          reason: failureReason,
          entity:
            p.paymentMethod.type === PaymentMethodType.card
              ? translate.common(p.locale).CreditDebitCardShort
              : translate.common(p.locale).BankAccount
        }
      )
    };
  }

  const now = Date.now();

  const newId = Math.random().toString().slice(3) + Math.random().toString().slice(3);
  let newPaymentMethod: PaymentMethod;
  if (p.paymentMethod.type === PaymentMethodType.card) {
    const cardMethod: PaymentMethod__Card = {
      type: PaymentMethodType.card,
      status: "active",
      createdAtMS: now,
      updatedAtMS: now,
      id: newId,
      brand: p.paymentMethod.brand,
      ownerName: p.paymentMethod.ownerName,
      isDefault: p.paymentMethod.isDefault,
      postalCode: p.paymentMethod.postalCode,
      expMM: p.paymentMethod.expMM,
      expYYYY: p.paymentMethod.expYYYY,
      cardNumberLast4: p.paymentMethod.cardNumber.trim().slice(-4),
      encryptedCardNumber: await encryptPIFI(p.paymentMethod.cardNumber.trim())
    };
    newPaymentMethod = cardMethod;
  } else {
    const cardMethod: PaymentMethod__BankAccount = {
      type: PaymentMethodType.bank,
      createdAtMS: now,
      updatedAtMS: now,
      id: newId,
      bankAccountType: p.paymentMethod.bankAccountType,
      ownerName: p.paymentMethod.ownerName,
      isDefault: p.paymentMethod.isDefault,
      routingNumber: p.paymentMethod.routingNumber,
      accountNumberLast4: p.paymentMethod.accountNumber.trim().slice(-4),
      encryptedAccountNumber: await encryptPIFI(p.paymentMethod.accountNumber.trim())
    };
    newPaymentMethod = cardMethod;
  }

  const paymentMethodUpdates: Record<string, Partial<PaymentMethod>> = {
    [newId]: newPaymentMethod
  };

  if (oldDefaultPaymentMethod) {
    paymentMethodUpdates[oldDefaultPaymentMethod.id] = {
      isDefault: h._MagicDeleteValue
    };
  }

  await h.AccountSecret.update({
    id: p.selfAccountId,
    doc: {
      paymentMethodsById: paymentMethodUpdates
    }
  });

  return { status: "success", paymentMethodId: newId };
  // SERVER_ONLY_TOGGLE
}

accountSecret__server__addPaymentMethod.auth = async (req: any) => {
  await validateToken(req);
};
