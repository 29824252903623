import { TeamColorSettings, TeamId, CALENDAR_ENTRY_TYPES, EventColorSetting } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function accountPrivate__client__updateEventColorSetting(p: {
  accountId: string;
  eventColorSetting: EventColorSetting;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      settings: {
        eventColorSetting: p.eventColorSetting
      }
    }
  });
}

// i18n certified - complete
