import { getUniversalHelpers } from "../../helpers";
import { Account } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function account__server__getAccountByEmail(p: { email: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let account: Account | undefined = undefined;
  let res = await h.Account.query({
    where: [{ email: ["==", p.email] }],
    limit: 1
  });

  if (res.docs.length > 0) {
    account = res.docs[0];
  }

  return account;
  // SERVER_ONLY_TOGGLE
}

account__server__getAccountByEmail.auth = async () => {};

// i18n certified - complete
