import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { AccountId, PaymentMethod } from "@ollie-sports/models";
import _ from "lodash";

export async function accountSecret__server__getPaymentMethodsForAccountIds(p: {
  accountIds: string[];
}): Promise<(PaymentMethod & { accountId: AccountId })[]> {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountSecrets = _.compact(await h.AccountSecret.getDocs(p.accountIds));

  return _.flatten(
    accountSecrets.map(accountSecret => {
      return _(accountSecret?.paymentMethodsById || {})
        .values()
        .map(v => {
          return { ...v, accountId: accountSecret.id };
        })
        .orderBy(a => {
          if (a.isDefault) {
            return 1;
          } else {
            return 0;
          }
        }, "desc")
        .value();
    })
  );

  // SERVER_ONLY_TOGGLE
}

accountSecret__server__getPaymentMethodsForAccountIds.auth = async (req: any) => {
  await validateToken(req);
};
